<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.check_point") }}
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-simple-table class="table-padding-2-no-top">
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                <InputFilter
                  :label="$t('labels.phone_number')"
                  :placeholder="$t('labels.phone_number')"
                  name="phone"
                  sort-name="phone"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilterFromTo
                  :label="$t('labels.spend')"
                  :placeholder="$t('labels.spend')"
                  name="spend"
                  sort-name="spend"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilterFromTo
                  :label="$t('labels.accrue_point')"
                  :placeholder="$t('labels.accrue_point')"
                  name="point"
                  sort-name="point"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilterFromTo
                  :label="$t('labels.used_point')"
                  :placeholder="$t('labels.used_point')"
                  name="used_point"
                  sort-name="used_point"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilterFromTo
                  :label="$t('labels.available')"
                  :placeholder="$t('labels.available')"
                  name="available_point"
                  sort-name="available_point"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in items"
              :key="`${index}_${item.id}`"
            >
              <td>{{ item.phone }}</td>
              <td>{{ item.name }}</td>
              <td>{{ formatNumber(item.spend) }}</td>
              <td>{{ formatNumber(item.point) }}</td>
              <td>{{ formatNumber(item.used_point) }}</td>
              <td>{{ formatNumber(item.available_point) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-center mt-3">
          <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="4"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "AccruePointBuyer",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  data: () => ({
    items: [],
    filters: {},
    page: 1,
    totalPage: 1,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-get-buyers", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
